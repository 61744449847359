import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import IconArrowUpRight from '@/assets/icons/icon-arrow-up-right.svg';
import { PATH_HOME } from '@/configs/path';
import { FlexBox } from './atom/FlexBox';
import { StyledText } from './atom/Text';

const StyledButton = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 24px;
  height: 60px;
  border-radius: 48px;
  margin-top: 48px;
  background-color: ${(p) => p.theme.colors.buttonPoint};
  font-size: ${(p) => p.theme.size.lg};
  ${(p) => p.theme.font.bold};

  & > div {
    border: 100%;
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 100%;
  }
`;

const Wrapper = styled(FlexBox)`
  height: 100vh;
  padding: 0 24px;

  @media (max-width: 430px) {
    & > img {
      width: 240px;
      height: 160px;
    }

    h1 {
      font-size: ${(p) => p.theme.size.xl};
    }

    p {
      font-size: ${(p) => p.theme.size.sm};
    }

    button {
      height: 56px;
      font-size: ${(p) => p.theme.size.md};
    }
  }
`;

interface Props {
  asset: string;
  title: string;
  message: string;
  buttonMessage: string;
}

export default function CustomError({
  asset,
  title,
  message,
  buttonMessage,
}: Props) {
  const router = useRouter();

  const handleBackClick = useCallback(() => {
    router.replace(PATH_HOME);
  }, [router]);

  return (
    <Wrapper $flex $direction="column" $justify="center" $align="center">
      <Image src={asset as string} width={360} height={240} alt="" />
      <FlexBox
        $flex
        $direction="column"
        $justify="center"
        $align="center"
        $gap={40}
      >
        <StyledText
          as="h1"
          $fontSize="xxxl"
          $fontWeight="bold"
          style={{ whiteSpace: 'pre-line' }}
        >
          {title}
        </StyledText>
        <StyledText
          as="p"
          $textAlign="center"
          style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
        >
          {message}
        </StyledText>
      </FlexBox>
      <StyledButton $flex as="button" onClick={handleBackClick}>
        {buttonMessage}
        <FlexBox $flex>
          <IconArrowUpRight />
        </FlexBox>
      </StyledButton>
    </Wrapper>
  );
}
