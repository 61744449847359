import { GetStaticProps } from 'next';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
// @ts-ignore
import Image404Error from '@/assets/images/404.svg?url';
// @ts-ignore
import ImageDefaultError from '@/assets/images/500.svg?url';
import CustomError from '@/components/CustomError';

export default function Error({ statusCode }: any) {
  const { t } = useTranslation();

  const is404 = statusCode === 404;

  return (
    <>
      <Head>
        <title>{t('common:metadata:title')}</title>
      </Head>
      <CustomError
        title={
          is404
            ? t('common:error:pageNotFound')
            : t('common:error:somethingWrong')
        }
        message={is404 ? t('common:error:pageNotFoundDesc') : ''}
        buttonMessage={t('common:error:backToHome')}
        asset={is404 ? Image404Error : ImageDefaultError}
      />
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({
  locale,
  res,
  err,
}: {
  locale?: string;
  res?: any;
  err?: any;
}) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  const languages = locale
    ? {
        ...(await serverSideTranslations(locale, ['common'])),
      }
    : {};

  return {
    props: {
      statusCode,
      ...languages,
    },
  };
};
